import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
export interface Ticket {
  id?: string;
  type?: string;
  attributes?: {
    subject: string;
    description: string;
    priority: string | null
    planned_startdate: string;
    planned_enddate: string;
    planned_effort: null | string;
    status: string | null
    source: string | null;
    impact: string | null;
    urgency: string | null;
    category: string | null;
    tags: string;
    ticket_template_id: string;
    created_at: string | null;
    cc: {
      id: string;
      full_name: string;
      email: string
    }[];
    group: {
      id: string | null;
      name: string | null;
    };
    requester: {
      id: string | null;
      name: string | null;
      email: string | null;
      full_name: string | null;
    };
    department: {
      id: string | null;
      name: string | null;
    };
    agent: {
      id: string | null;
      name: string | null;
      email: string | null;
    }
  }
}
interface TicketTemplateAttributes {
  title: string;
  description: string;
  available_for: string;
  ticket: {
    data: {
      id: string;
      type: string;
      attributes: TicketAttributes;
    };
  };
  ticket_template_attachments: null | any[];
}

interface TicketAttributes {
  subject: string;
  description: string;
  priority: string;
  planned_startdate: string;
  planned_enddate: string;
  planned_effort: string;
  status: string;
  source: string;
  impact: null | string;
  urgency: null | string;
  category: string;
  tags: string;
  ticket_template_id: number;
  created_at: string;
  planned_start_time: null | string;
  planned_end_time: null | string;
  cc: any[]; 
  group: null | {
    id: null | number; 
    name: null | string;
  };
  requester: null | {
    id: null | number; 
    full_name: null | string;
    email: null | string;
  };
  department: null | {
    id: null | number;
    name: null | string;
  };
  agent: null | {
    id: null | number; 
    full_name: null | string;
    email: null | string;
  };
  ticket_attachments: null | any[]; 
}


interface TicketTemplate {
  id: string;
  type: string;
  attributes: TicketTemplateAttributes;
}
interface GroupedByStatus {
  [key: string]: Ticket[];
}

interface GroupedByStatusNew {
  [status: string]: {
    tickets: Ticket[];
    count: number;
  };
}

interface ApiCallIds {
  [ky: string]: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: number;
  // Customizable Area Start
  name: string;
  token: string;
  isLoading: boolean
  filterBy: 'all templates' | 'status' | 'priority'
  anchorEl: HTMLElement | null | undefined,
  createAnchorEl: HTMLElement | null | undefined,
  open: boolean,
  showModal: boolean;
  allTemplates: TicketTemplate[]
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class FormTemplatesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllTemplateApiCallId: string = "";
  apiCallIds: ApiCallIds = {
    all : "",
    open: '',
    closed: '',
    pending: '',
    resolved: '',
    Low: "",
    Medium :"",
    High : "",
    Urgent : ""
  };
  token: string = '';
  status: { title: string, id: string }[] = [
    {
      id: 'open',
      title: 'Open',
    },
    {
      id: 'closed',
      title: 'Closed',
    },
    {
      id: 'pending',
      title: 'Pending',
    },
    {
      id: 'resolved',
      title: 'Resolved'
    }
  ]
  priority = [
    {
      id: 'Low',
      title: 'Low'
    },
    {
      id: 'Medium',
      title: 'Medium'
    },
    {
      id: 'High',
      title: 'High'
    },
    {
      id: 'Urgent',
      title: 'Urgent'
    },
  ]

  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: 0,
      name: "",
      isLoading: false,
      token: "",
      anchorEl: null,
      createAnchorEl: null,
      open: false,
      showModal: false,
      filterBy: 'all templates',
      allTemplates: []
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.token = await getStorageData('authToken')
    this.getToken();
    this.getAllFormTemplates()
   // this.getAllTickets()
  }

  receive = async (from: string, message: Message) => {
    this.handleMessageReception(message);

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    this.setState({ isLoading: false });
    this.handleTokenExpiryAndInvalidToken(responseJson);

    if (apiRequestId === this.getAllTemplateApiCallId) {
      this.handleAllTemplateResponse(responseJson);
    }
  };

  handleMessageReception(message: Message) {
    runEngine.debugLog("Message Received", message);
  }

  handleTokenExpiryAndInvalidToken(responseJson: any) {
    if (responseJson && Array.isArray(responseJson.errors) && responseJson.errors.length > 0 &&
      (responseJson.errors[0].token === "Token has Expired" || responseJson.errors[0].token === 'Invalid token')) {
      this.goToLogin();
    }
  }

  handleAllTemplateResponse(responseJson: any) {
    if (responseJson.data) {
      console.log('all templates', responseJson.data)
      this.setState({ allTemplates: responseJson.data });
    }
  }


  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getAllFormTemplates = () => {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllTemplateApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllTemplatesApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleNavigation = (path: string | undefined) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  getStateColor = (state: string | undefined) => {
    if (state) {
      const stateColorMapping: { [key: string]: string } = {
        'ticket': '#11A64A',
        'change': '#6366F1',
      };

      return stateColorMapping[state] || '#11A64A'
    } else {
      return '#11A64A'
    }
  };

  handleRowClick = async (ticket: Ticket) => {
    if (ticket && ticket.id) {
      await setStorageData('selectedTicketId', ticket.id);
      this.props.navigation.navigate('Task', { id: ticket.id });
    }
  }

  getCapitalizeName = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
      open: false,
    });
  }

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl: event?.currentTarget,
      open: true,
    });
  }

  groupByStatusNew(ticketList: Ticket[], count: number): GroupedByStatusNew {
    return ticketList.reduce((acc, ticket) => {
      const statusValue = ticket.attributes?.status as string;
      if (!acc[statusValue]) {
        acc[statusValue] = {
          tickets: [],
          count: 0,
        };
      }
      acc[statusValue].tickets.push(ticket);
      acc[statusValue].count = count;
      return acc;
    }, {} as GroupedByStatusNew);
  }

  groupByPriorityNew(ticketList: Ticket[], count: number): GroupedByStatusNew {
    return ticketList.reduce((acc, ticket) => {
      const priorityValue = ticket.attributes?.priority as string;
      if (!acc[priorityValue]) {
        acc[priorityValue] = {
          tickets: [],
          count: 0,
        };
      }
      acc[priorityValue].tickets.push(ticket);
      acc[priorityValue].count = count;
      return acc;
    }, {} as GroupedByStatusNew);
  }

  groupByStatus(ticketList: Ticket[]): GroupedByStatus {
    return ticketList.reduce((acc, ticket) => {
      const statusValue = ticket.attributes?.status as string;
      if (!acc[statusValue]) {
        acc[statusValue] = [];
      }
      acc[statusValue].push(ticket);
      return acc;
    }, {} as GroupedByStatus);
  }

  groupByPriority(ticketList: Ticket[]): GroupedByStatus {
    return ticketList.reduce((acc, ticket) => {
      const priorityValue = ticket.attributes?.priority as string;
      if (!acc[priorityValue]) {
        acc[priorityValue] = [];
      }
      acc[priorityValue].push(ticket);
      return acc;
    }, {} as GroupedByStatus);
  }

  getShortUserName = (value:string)=>{
    if(value){
      const userNameArr = value.toUpperCase().split(" ")
      if(userNameArr.length>1){
        return `${userNameArr[0][0]}${userNameArr[1][0]}`
      }else{
        return `${userNameArr[0][0]}`
      }
    }
  }
  
  handleCreateTemplate = (event: React.MouseEvent<HTMLElement>) => this.setState({ 
    createAnchorEl: event.currentTarget,
    showModal: true 
  })

  handleCloseCreateTemplate = () => {
    this.setState({ 
      createAnchorEl: null,
      showModal: false 
    })
  }

  
  // Customizable Area End
}
