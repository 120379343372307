Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform3";
exports.labelBodyText = "customform3 Body";

exports.btnExampleTitle = "CLICK ME";

exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.getAllGroupsApiEndPoint = 'bx_block_department/groups?user_type=0';
exports.getAllAgentsApiEndPoint = "bx_block_department/agent_and_requesters";
exports.getAllRequestersApiEndPoint = 'bx_block_department/agent_and_requesters/get_all_requester';
exports.getAllDepartmentsApiEndPoint = "bx_block_department/departments";
exports.getAllTemplatesApiEndPoint = "bx_block_custom_form/ticket_templates";
exports.createTicketApiEndPoint = "bx_block_custom_form/tickets"
exports.createTicketTemplate ="bx_block_custom_form/ticket_templates"
exports.createFormTemplateApiEndPoint = "bx_block_custom_form/ticket_templates"
exports.getSingalTemplateApiEndPoint = "bx_block_custom_form/ticket_templates/"
exports.createTemplateApiEndpoint = "bx_block_custom_form/ticket_templates"
exports.formDataContentType= "multipart/form-data"
exports.specialCharPattern  = /^[A-Za-z0-9]/;

exports.textSelectTemplate = "Select template";
exports.textModalDesc = "Fill your form in a click using predefined form values.";
exports.textCreateNewTemplate = "Create new template";
exports.textRequester = "Requester"
exports.textSubject = "Subject"
exports.textSource = "Source"
exports.textStatus = "Status"
exports.textPriority = "Priority"
exports.textGroup = "Group"
exports.textAgent = "Agent"
exports.generalTitle= "General"
exports.title= "Title"
exports.titlePlaceholder= "Enter title"
exports.available_for = "Available for"
exports.detailsTitle= "Details"
exports.textDepartment = "Department"
exports.textDescription = "Description"
exports.textDescriptionPlaceholder = "Enter description"
exports.textCategory = "Category"
exports.textPlannedSdate= "Planned start date"
exports.textPlannedEdate= "Planned end date"
exports.textPlannedEffort = "Planned effort"
exports.textPlannedEffortPlaceholder = "Specify effort (eg. 02h 00m)"
exports.textTags = "Tags"
exports.textSearch = "Search"
exports.textEnter = "Enter"
exports.textCancel = "Cancel"
exports.textSave = "Submit"
exports.textAnnouncements = "Announcements"
exports.textViewAllAnnouncement = "View all announcements"
exports.textCreateNewAnnouncement = "Create new announcement"
exports.textCreateNewIncident = "Create new incident"
exports.textCreateNewTicket = "Create new ticket"
exports.textTickets =  "Tickets"
exports.textLeaderboard =  "Leaderboard"
exports.textEnterNote = "Enter note"
exports.textEnterSubject = "Enter subject"
exports.textChooseCategory = "Choose category"
exports.textNone = "None"
exports.textAddCC= "Add CC"
exports.textAddNewRequester = "Add new requester"
exports.inputFieldEmptyErrorMessage = "cannot be empty or only whitespace"
exports.inputFieldSpecialCharMessage = "cannot start with a special character or space"
exports.successTicketCreateMessage = "Your ticket has been submitted. To access it head to tickets tab."
exports.successTicketTemplateCreateMessage = "Your ticket template has been created."
exports.newticketTemplate ="New ticket template"
exports.colorGray = '#A3A6C2'
exports.colorBlack = '#33354D'

exports.exampleEmail = 'name.surname@mail.com'
exports.grouoFieldPlaceholder  = 'Assign team'
exports.agentFieldPlaceholder = 'Assign person'
exports.departmentFieldPlaceholder = 'Assign department'
exports.descriptionRequired="Description is required"
exports.descriptionCharcter = "Description must be at least 10 characters long";
exports.requesterReq="Requester is required";
exports.subjectReq="Subject is required";
exports.titleReq="Title is required";
exports.titleCharcter = "Title must be at least 5 characters long";
exports.search = "Search...";
exports.assignteam = "Assign Team";
exports.enternote = "Enter Note";
exports.formValidation = "Form validation failed. Please fill in all required fields.";
exports.requesterListAPIEndPoint = "/bx_block_custom_form/requester_tickets/get_all_requester";
exports.createRequesterTickets = "bx_block_custom_form/requester_tickets"
exports.textChangeType = "Change Type"
exports.textEnterNote = "Enter note"
exports.textReasonForChange = "Reasons for change"
exports.textRolloutPlan = "Rollout plan"
exports.textImpact = "Impact"
exports.textBackoutPlan = "Backout plan"
exports.textCreatNewChanges = "Create new change request"
exports.textSubCategory = "Sub-Category"
exports.textDetails  = 'Details'
exports.textPlanning  = 'Planning'
exports.txtEnterDescription = "Enter description"
exports.txtAssociateAsset = "Associate asset"
exports.txtMaintanceWindow = "Maintance window"
exports.textSubmitRequest = "Submit request"
exports.departmentFieldPlaceholder = 'Assign department'
exports.textEnterEmailAddress = 'Enter email address'
exports.textDateFormtes = 'DD-MM-YYYY'
exports.textTimeFormates = "HH-MM"
exports.textDashboards = "Dashboards"
exports.textDashboardName = "Dashboard Name"
exports.startDateError = "Selected date cannot be before the current date."
exports.endDateError = "Selected date cannot be before the start date selected."
exports.startTimeError = "Selected time cannot be in the past."
exports.endTimeError = "End time cannot be before the start time."
exports.startDateErrorValidation = "Start date cannot be after the end date."
exports.sourceReq="Source is required";
exports.statusReq="Status is required";
exports.startTimeError="Start time is required";
exports.endTimeError="End time is required";
exports.dueDate="Due Date"
exports.textAllTemplate = "All Templates";
exports.settings = "Settings";
exports.userManagement = "User management";
exports.formTemplate = "Form Template";
exports.ticketTemplate = "Ticket Template";
exports.changeTemplate = "Change Template";
// Customizable Area End