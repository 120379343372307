import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  ButtonGroup,
  styled,
  Chip,
  Menu,
  MenuItem
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ArrowDropDown } from "@material-ui/icons";
import Loader from "../../../components/src/Loader.web";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import FormTemplatesController,  { configJSON, Props } from "./FormTemplatesController";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#8760A9",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiCheckbox: {
      root: {
        color: '#8760A9'
      },
      input: {
        borderRadius: '5px'
      },
      colorSecondary: {
        '&$checked': {
          color: '#8760A9',
        },
      },
    },
  }
});


const CustomChip = styled(Chip)({
  color: '#FFF',
  fontFamily: 'Manrope',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  borderRadius: '4px !important',
  border: '0',
  "& .MuiChip-clickable.MuiChip-outlined:focus": {
    backgroundColor: '#11A64A'
  }
})

const CustomMenuItem = styled(MenuItem)({
  padding: '9px 16px'
})

// Customizable Area End


export default class FormTemplates extends FormTemplatesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  returnChip = (status: string) => {
    return <CustomChip
      label={status}
      size="medium"
      variant="outlined"
      style={{ background: this.getStateColor(status), width: "5rem" }}
    />
  }

  renderList = () => {
    const {allTemplates} = this.state
    return (
      allTemplates.length > 0 ? <>
        {allTemplates.map((template, index: number) => (
          <Box key={index} style={webStyles.listBox}>
          <Box style={webStyles.listItem}>
            <Typography style={webStyles.listHeading}>{template.attributes.title}</Typography>
            <Typography style={webStyles.listDesc}>{template.attributes.ticket.data.attributes.description}</Typography>
          </Box>
          {template.type == 'ticket_template' ? this.returnChip("Ticket") : this.returnChip("Change")}
        </Box>
        ))}
      </> : <Typography style={{textAlign:'center'}}>No Templates available</Typography>
    )
  }

  render() {
    // Customizable Area Start
    return (
      <>
        <NavigationSideBar active="Settings2" navigation={this.props.navigation} />
        <Loader loading={this.state.isLoading} />
        <ThemeProvider theme={theme}>
          <Box style={webStyles.task_container}>
            <Box style={webStyles.topheader}>
              <span style={webStyles.navigation}>
                {configJSON.settings} {">"} {configJSON.userManagement} {">"} {configJSON.formTemplate}
              </span>
              <Box style={webStyles.inputMainBox}>
                <ButtonGroup
                  orientation='horizontal'
                  color="primary"
                  variant="contained"
                  style={{ textTransform: 'none' }}
                >
                  <Button style={{ textTransform: 'none' }}>
                    {configJSON.textCreateNewTemplate}
                  </Button>
                  <Button 
                  id="create-button"
                  aria-controls={this.state.showModal ? 'create-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={this.state.showModal ? 'true' : undefined}
                  data-testid="createNewTemplate" style={{ textTransform: 'none' }}
                    onClick={this.handleCreateTemplate}
                  >
                  <ArrowDropDown />
                  </Button>
                  <Menu
                    id="create-menu"
                    data-testid="createMenu"
                    anchorEl={this.state.createAnchorEl}
                    open={this.state.showModal}
                    disableScrollLock
                    onClose={this.handleCloseCreateTemplate}
                    MenuListProps={{
                      'aria-labelledby': 'create-button',
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    getContentAnchorEl={null}
                  >
                    <CustomMenuItem data-testid="create-ticket-template" onClick={() => this.handleNavigation('TicketTemplateCustomForm')}>
                      {configJSON.ticketTemplate}
                    </CustomMenuItem>
                    <CustomMenuItem data-test-id="create-change-template" onClick={() => {}}>
                      {configJSON.changeTemplate}
                    </CustomMenuItem>
                  </Menu>
                </ButtonGroup>
              </Box>
            </Box>
            <Typography style={webStyles.mainHeading}>{configJSON.formTemplate}</Typography>

            <Box style={webStyles.main_content_card}>
              <Typography style={webStyles.innerHeading}>{configJSON.formTemplate}</Typography>
              <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} width={'100%'} mt={'25px'} mb={'30px'}>
                <ButtonGroup
                  orientation='horizontal'
                  variant="contained"
                  style={webStyles.filterByStyle}
                >
                  <Button style={{ textTransform: 'none' }}
                >
                  <span style={{ fontWeight: 'bold', marginLeft: "2px" }}> {this.getCapitalizeName(this.state.filterBy)}</span>
                </Button>
                  <Button
                    id="basic-button"
                    data-test-id="basicButton"
                    aria-controls={this.state.open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={this.state.open ? 'true' : undefined}
                    onClick={this.handleClick}>
                    <ArrowDropDown />
                  </Button>
                  <Menu
                    id="basic-menu"
                    data-test-id="basicMenu"
                    anchorEl={this.state.anchorEl}
                    open={this.state.open}
                    onClose={this.handleClose}
                    disableScrollLock
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    getContentAnchorEl={null}
                  >
                    <CustomMenuItem data-test-id="filterAll" onClick={() => {}}>
                      {configJSON.textAllTemplate}
                    </CustomMenuItem>
                  </Menu>
                </ButtonGroup>
              </Box>
              {this.renderList()}
            </Box>
          </Box>
        </ThemeProvider>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  topheader: {
    display: "flex",
    justifyContent: "space-between",
    padding: '24px 0'
  } as const,
  modalRowViewStyle: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
  },
  modalButtonViewStyle: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 0px",
  },
  tableViewStyle: {
    width: "100%",
    overflow: "hidden",
  },
  tableContainerStyle: {
    maxHeight: 440,
  },
  tableButtonViewStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  headerButtonViewStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  secondButtonViewStyle: {
    marginLeft: 10,
  },
  dropdownViewStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 280,
    justifyContent: "space-between",
    padding: 10,
    border: "1px solid #ccc",
    borderRadius: 5,
  },
  dropdownListContainer: {
    maxHeight: 200,
    overflow: "auto",
    width: 300,
  },
  truncatedText: {
    overflow: 'hidden',
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  } as const,
  navigation: {
    color: '#3D405C',
    fontSize: '14px',
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: 'normal',
  } as const,
  inputMainBox: {
    fontSize: '14px',
    color: '#33354D',
    fontStyle: 'normal',
    lineHeight: '24px',
    fontWeight: 'bolder',
    fontFamily: 'Manrope',
    cursor: 'pointer',
    alignItems: "center",
    display: 'flex',
    gap: '10px'
  } as const,
  input: {
    width: "100%",
    border: "1px solid #e6deed",
    fontFamily: "Manrope",
    maxWidth: '200px',
    fontSize: "16px",
    borderRadius: "10px",
    height: "40px",
    padding: '10px 8px'
  } as const,
  table_label: {
    color: "#3D405C",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: 700,
    opacity: '.5'
  } as const,
  mainHeading: {
    fontFamily: 'Manrope',
    color: '#33354',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    fontSize: '30px',
    padding: '40px 0'
  } as const,
  innerHeading: {
    fontFamily: 'Manrope',
    color: '#33354',
    fontWeight: 700,
    fontSize: '20px',
  } as const,
  main_content_card: {
    padding: 16,
    boxShadow: "rgba(135, 96, 169, 0.16) 0px 8px 32px 0px, rgba(135, 96, 169, 0.13) 0px 4px 8px 0px",
    borderRadius: "8px",
    alignSelf: "flex-start",
    flex: "1",
    marginBottom: '1rem'
  } as const,
  export_button: {
    fontWeight: "bold",
    fontSize: "16px",
    fontFamily: "Manrope",
    color: "#8760A9",
    letterSpacing: "0em",
    lineHeight: "16px",
    textTransform: "capitalize",
  } as const,
  rightBorder: {
    borderRadius: '1px',
    height: '20px',
    width: '2px',
    background: '#E6DEED',
    margin: '0 3px'
  } as const,
  export_button_border: {
    fontWeight: "bold",
    fontFamily: "Manrope",
    fontSize: "16px",
    color: "#8760A9",
    lineHeight: "16px",
    textTransform: "capitalize",
    letterSpacing: "0em",
  } as const,
  cardHead: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  } as const,
  card_heading: {
    fontFamily: 'Manrope',
    color: "#33354D",
    lineHeight: "normal",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: 700,
  } as const,
  filterByStyle: {
    color: '#33354D',
    textTransform: 'none',
    fontStyle: 'normal',
    fontFamily: 'Manrope',
    fontSize: '16px',
    lineHeight: 'normal',
    fontWeight: 'bold',
  } as const,
  ticket_count: {
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "bold",
  } as const,
  plus_style: {
    height: '24px',
    width: '24px',
    background: '#E6DEED',
    borderRadius: '50%',
    color: 'black',
    padding: '3px',
    cursor: 'pointer',
  } as const,
  task_container: {
    height: "100%",
    margin: "15px 15px 15px 300px",
    paddingBottom: "25px",
    fontFamily: "Manrope",
  } as const,
  table_card: {
    marginBottom: "1rem",
    borderRadius: "8px",
    padding: "16px",
    boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.24)"
  } as const,
  DetailsboxSupport: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center"
  } as const,
  fieldNameSupport: {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: "11px",
    lineHeight: "20px",
    color: "#A3A6C2",
    display: "flex",
    justifyContent: "start",
    letter: "1%"
  } as const,
  fieldValue: {
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#33354D"
  } as const,
  textSubjectName: {
    paddingTop: "5px"
  },
  listBox: {
    display: 'flex',
    padding: 12,
    justifyContent: 'space-between',
    border: '1px solid #E6DEED',
    borderRadius: 8,
    marginBottom: 8
  } as const,
  listItem: {
    display: 'flex',
    flexDirection: 'column'
  } as const,
  listHeading: {
    fontFamily: "Manrope",
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "24px",
    color: '#33354D'
  } as const,
  listDesc: {
    fontFamily: "Manrope",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "19.12px",
    color: '#A3A6C2'
  } as const
};
// Customizable Area End
